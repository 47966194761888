// 财务信息
<template>
  <div class="financiaInfo">
    <!-- <div class="addBtn">
      <div class="addButton" @click="addTableItem()">
        <i class="el-icon-plus"></i>
      </div>
    </div> -->
    <div class="box" ref="financiaInfo">
      <!-- 左侧固定列 -->
      <div class="left">
        <div class="leftTop">
          <div>序号</div>
          <div>科目(元)/周期(年)</div>
        </div>
        <div
          v-for="(item, index) of leftConfig"
          :key="item.label"
          class="leftData"
        >
          <div>{{ index + 1 }}</div>
          <div>{{ item.label }}</div>
        </div>
      </div>
      <!-- 右侧增加内容 -->
      <div class="right">
        <div v-for="(item, index) of renderData" :key="index" class="rightBox">
          <div
            v-for="(elem, indexOn) of item"
            :key="indexOn"
            :class="{ rightTop: indexOn === 0 }"
          >
            <div v-show=" elem.year === ''&&indexOn === 0" class="addRightTop">
              <base-select
              v-model="elem.year"
              :options="yearList"
              :selectedField="['id', 'year']"
              :clearable="true"
              @visible-change="yearfun"
              @change="changeyear(elem, index)"
               placeholder="请选择年份"
              ></base-select>
              <!-- <div class="delColumn" @click="delColumn(elem,index)">
                <i  class="iconfont iconshanchu2"></i>
              </div> -->
            </div>
            <div v-show=" elem.year !== ''&&indexOn === 0" class="top02">
               <div> {{ getKey(elem) }}</div>
               <!-- <div class="delColumn" @click="delColumn(elem,index)">
                <i  class="iconfont iconjianhao"></i>
              </div> -->
            </div>
             <div v-show="!elem.add&&indexOn !== 0">
              {{ getKey(elem) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增 -->

    </div>
</template>

<script>
// import SzInput from '@/components/input/sz-input/sz-input.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import { dueDiligenceApi } from '@/api/businessApi'
import Storage from '@/utils/storage'

export default {
  props: {
    tableData: Array
  },
  components: { BaseSelect },
  data () {
    return {
      visibleState: false,
      addData: {},
      // 左边表基本信息
      leftConfig: [
        { label: '货币资金', prop: 'cashEquivalents' },
        { label: '应收账款', prop: 'accountsReceivable' },
        { label: '其他应收款', prop: 'otherReceivables' },
        { label: '预付账款', prop: 'prepayment' },
        { label: '总资产合计', prop: 'totalAssets' },
        { label: '总负债合计', prop: 'totalLiability' },
        { label: '主营业务收入', prop: 'primeOperatingRevenue' },
        { label: '净利润', prop: 'netProfit' },
        { label: '净利润率(%)', prop: 'netProfitRate' },
        { label: '资产负债率(%)', prop: 'debtAssetRatio' },
        { label: '应收账款周转率(%)', prop: 'receivablesTurnoverRatio' },
        { label: '现金短债比', prop: 'cashRatio' }
      ],
      yearList: [], // 年份选项
      oldYearList: [],
      // 基本数据柱
      TemplateData: {
        year: '',
        cashEquivalents: '',
        accountsReceivable: '',
        otherReceivables: '',
        prepayment: '',
        totalAssets: '',
        totalLiability: '',
        primeOperatingRevenue: '',
        netProfit: '',
        netProfitRate: '',
        debtAssetRatio: '',
        receivablesTurnoverRatio: '',
        cashRatio: '',
        timer: ''
      },
      // 用于渲染地数据
      renderData: []
      // 用于保存地数据
      // confingData: []
    }
  },
  // 计算属性 类似于data概念
  computed: {
    confingData: {
      get () {
        return this.tableData
      },
      set (val) {
        this.$emit('changeConfigData', val)
      }
    }

  },
  // 监控data中的数据变化
  // watch: {
  //   confingData: {
  //     handler (val) {
  //       if (val) {
  //         clearInterval(this.timer)
  //       }
  //     },
  //     deep: true
  //   }
  // },
  mounted () {
    this.financialanalysisGetYears()
    // 如果有值之后就将数据付给渲染数组
    setTimeout(() => {
      this.confingData.forEach((item, index) => {
        this.renderData.push(this.formatData(this.filterData(item)))
      })
    }, 2000)
  },

  // 方法集合
  methods: {
    // 循环数据
    intervalFn () {

    },
    // 新增年份信息
    submitAdd () {
      this.$refs.addFromData.validate((valid) => {
        if (valid) {
          this.addData.year = (new Date(this.addData.year)).getFullYear()
          this.yearList.push({ id: this.addData.year.toString(), year: this.addData.year.toString() })
          this.addData.year = this.addData.year.toString()
          this.renderData.push(this.formatData(this.filterData(this.addData)))
          this.confingData.push(this.addData)
          this.visibleState = false
        }
      })
    },
    // 打开弹窗新增
    showAdd () {
      console.log('弹窗新增')
      this.addData = {}
      this.visibleState = true
    },
    // 查询可选年份
    financialanalysisGetYears () {
      dueDiligenceApi.financialanalysisGetYears({ gysId: Storage.getLocal('contractInfo').gysId }).then(res => {
        if (res.data) {
          res.data.forEach(item => {
            this.yearList.push({ id: item.toString(), year: item.toString() })
          })
        }
        this.oldYearList = JSON.parse(JSON.stringify(this.yearList))
      })
    },
    // 格式化成渲染的类型
    formatData (data) {
      const newArr = []
      for (const key in data) {
        let obj = {}
        obj = { [key]: data[key] }
        newArr.push(obj)
      }
      return newArr
    },
    // 排序模板数据顺序一样
    filterData (data) {
      const obj = {}
      for (const key in this.TemplateData) {
        for (const j in data) {
          if (key === j) {
            obj[key] = JSON.parse(JSON.stringify(data[j]))
          }
        }
      }
      return obj
    },
    // 选择年
    changeyear (data, index) {
      if (data.year) {
        const parmas = { businessId: this.$route.query.businessId, year: data.year }
        dueDiligenceApi.financialanalysisGetByYear(parmas).then(res => {
          this.renderData.splice(index, 1, this.formatData(this.filterData(res.data)))
          this.confingData.push(res.data)
          console.log(res.data, 'res.data')
          // this.$emit('changeAssetInfoData', res.data)
          // console.log(this.confingData, '====')
        })
      }
    },
    // 删除一列
    delColumn (data, index) {
      this.renderData.splice(index, 1)
      this.confingData.splice(index, 1)
    },
    // 财务信息数据格式化
    formatAnalysisList (data) {
      const newArr = []
      data.forEach((item) => {
        const obj = {}
        for (const key in this.TemplateData) {
          for (const j in item) {
            if (key === j) {
              obj[key] = JSON.parse(JSON.stringify(item[j]))
            }
          }
        }
        newArr.push(obj)
      })
      return newArr
    },

    // 新增列
    addTableItem () {
      const arr =
        {
          year: '',
          cashEquivalents: '',
          accountsReceivable: '',
          otherReceivables: '',
          prepayment: '',
          totalAssets: '',
          totalLiability: '',
          primeOperatingRevenue: '',
          netProfit: '',
          netProfitRate: '',
          debtAssetRatio: '',
          receivablesTurnoverRatio: '',
          cashRatio: ''
        }
      this.renderData.push(this.formatData(arr))
      this.yearfun()
    },
    // 去除已近选中的年份
    yearfun () {
      const yeaArr = JSON.parse(JSON.stringify(this.oldYearList))
      this.confingData.forEach((item, index) => {
        yeaArr.forEach((ele, i) => {
          if (item.year.toString() === ele.year) {
            yeaArr.splice(i, 1)
          }
        })
      })
      this.yearList = yeaArr
    },
    getKey (item) {
      for (const key in item) {
        if (key !== 'add') {
          return item[key]
        }
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.financiaInfo{
  padding: 10px;
}
.addBtn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background: #fff;
  .addButton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #6996FF;
    }
  }
}
.right {
  display: flex;
  max-width: calc(100%-235px);
  // overflow-x: auto;
  overflow-y: hidden;
  overflow-x: scroll;
  .rightTop {
    background: #EBF1FF;
  }
  div {
    border: 1px solid #B6CCFF;
    border-left: 0;
    border-top: 0;
    text-align: center;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    width: 308px;
  }
}
.box {
  display: flex;
}
.leftData {
  display: flex;
  div {
    border: 1px solid #B6CCFF;
    border-left: 0;
    border-top: 0;
    text-align: center;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
  }
  div:nth-child(1) {
    width: 60px;
  }
  div:nth-child(2) {
    width: 200px;
  }
}
.leftTop {
  display: flex;
  div {
    background: #EBF1FF;
    font-size: 14px;
    border: 1px solid #B6CCFF;
    border-left: 0;
    border-top: 0;
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
  div:nth-child(1) {
    width: 60px;
  }
  div:nth-child(2) {
    width: 200px;
  }
}
.addRightTop{
  display: flex;

}

.top02{
  position: relative;
  .delColumn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform:translateY(-50%);
    background: #2862E7;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 22px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
